import React, { useState } from 'react';
import { Menu, Grid, HelpCircle, XCircle, ArrowRight, LogOut } from 'react-feather';
import { TERMS_VERSION, getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { Link } from '@reach/router';
import cx from 'classnames';
import logo from '../../images/boomer.png'
import profileDefault from '../../images/profile-default.svg';
import styles from './Nav.module.scss';
import ls from 'local-storage';
import { SCHOOL_SETUP_STEPS, SCHOOL_MATH_SETUP_STEPS } from '../../services/constants';


const Nav = ({ user, source }) => {

  const [showMenu, setShowMenu] = useState(false)

  const isActive = ({ isCurrent }) => {
    return isCurrent ? { className: cx(styles.subHeading, styles.subHeadingActive) } : { className: styles.subHeading }
  }

  const subLinkIsActive = ({ isCurrent }) => {
    return isCurrent ? { className: cx(styles.subLink, styles.subLinkActive) } : { className: styles.subLink }
  }

  const showFullMenu = () => {
    if (ls('country') == 'US') {
      return (user.school && user.school.setupStep === null) || user?.school?.setupStep >= SCHOOL_MATH_SETUP_STEPS.finalStepDone
    }
    return (user.school && user.school.setupStep === null) || user?.school?.setupStep >= SCHOOL_SETUP_STEPS.finalStepDone
  }

  const getAppName = () => {
    if (source === 'PORTAL') {
      if (ls('country') === 'US') {
        return APPS.boom_math_portal_teacher;
      } else {
        return APPS.go_portal_teacher;
      }
    }
    if (source === 'WRITING') {
      return APPS.go_write_teacher;
    }
    if (source === 'READING') {
      return APPS.go_read_teacher;
    }
    if (source === 'MATHS') {
      return APPS.boom_math_teacher;
    }
    return null;
  };

  const token = ls(getAppTokenName(getAppName()));

  return (
    <>
      <div className={styles.mobNav} onClick={() => setShowMenu(!showMenu)}>
        <span className={styles.mobNavGrid}>
          <Grid size="24" />
        </span>
        <span className={styles.mobNavMenu}>
          <span className="u-m-right-1">MENU</span>
          <Menu size="20" />
        </span>
      </div>
      <div className={styles.nav}>
        {parseInt(user.lastTermsVersionApproved) >= TERMS_VERSION && (
          <ul className={styles.navMenu}>
            <li>
              <Link
                to='/'
                className={styles.navMenuLink}
                onClick={() => setShowMenu(false)}
              >
                {source === 'PORTAL' && <Grid size="24" />}
                {source === 'WRITING' && (
                  <span className={styles.letterIcon}>W</span>
                )}
                {source === 'READING' && (
                  <span className={styles.letterIcon}>R</span>
                )}
                {source === 'MATHS' && (
                  <span className={styles.letterIcon}>M</span>
                )}
              </Link>
            </li>
            <span className={styles.navMenuAppText}>School Hub</span>
            <li>
              <span
                onClick={() => setShowMenu(!showMenu)}
                className={cx(styles.navMenuBtn, 'menuBtn')}
              >
                <Menu size="24" />
                <span className={styles.navMenuBtnText}>MENU</span>
              </span>
            </li>
          </ul>
        )}
        <div className={styles.navExit}>
          <Link to="/" className={styles.navBrand}>
            <img src={logo} />
          </Link>
        </div>
      </div>
      {showMenu && (
        <div
          className={cx(styles.sideOverlay, 'sideOverlay')}
          onClick={(event) => {
            if (event.target.classList.contains('sideOverlay')) {
              setShowMenu(false);
            }
          }}
        >
          <div className={styles.sideMenu}>
            {showFullMenu() &&
              <Link
                to="/profile"
                onClick={() => {
                  setShowMenu(false);
                }}
                className={styles.profileLink}
              >
                <img
                  className={styles.profileImg}
                  src={user.avatarUrl || profileDefault}
                />
                <p className={styles.profileName}>{user.displayName}<span className={styles.profileManage}>Manage Profile</span></p>
              </Link>
            }
            <div className={styles.sideLinks}>
              {showFullMenu() && (
                <>

                  {source === 'PORTAL' ? (
                    <>
                      <Link to="/" getProps={isActive} onClick={() => setShowMenu(false)}>
                        <span className={styles.appIcon}><Grid size="16" color="#FFF" /></span>School Hub
                      </Link>
               
                      <div className={styles.subSet}>
                        {user.isSchoolAdmin && !user.school.wondeAccessApprovedAt &&
                          <Link
                            onClick={() => setShowMenu(false)}
                            getProps={subLinkIsActive}
                            to={`/students`}
                          >
                            <ArrowRight className={styles.subLinkArrow} size="18" />
                            Manage Students
                          </Link>
                        }
                          <Link
                            onClick={() => setShowMenu(false)}
                            getProps={subLinkIsActive}
                            to={
                              !user.isSchoolAdmin && user.studentsClasses.length === 1
                                ? `/classes/${user.studentsClasses[0].id}`
                                : `/classes`
                            }
                          >
                            <ArrowRight className={styles.subLinkArrow} size="18" />
                            Manage Classes
                          </Link>
                          {user.isSchoolAdmin && (
                            <>
                              <Link
                                onClick={() => setShowMenu(false)}
                                getProps={subLinkIsActive}
                                to="/teachers"
                              >
                                <ArrowRight className={styles.subLinkArrow} size="18" />
                                Manage Staff
                              </Link>
                              <Link
                                onClick={() => setShowMenu(false)}
                                getProps={subLinkIsActive}
                                to="/settings/schoolProfile"
                              >
                                <ArrowRight className={styles.subLinkArrow} size="18" />
                                App Settings/Subscriptions
                              </Link>
                            </>
                          )}
                        </div>
                      </>
                      ) : (
                      <a
                        href={getUrl(APPS.go_portal_teacher)}
                        className={styles.appLink}
                      >
                        School Hub
                      </a>
                  )}
                      {ls('country') === 'US' ? (
                        <>
                          {source === 'MATHS' ? (
                            <span className={styles.subHeading}>Math Home</span>
                          ) : (
                            <a
                              href={`${getUrl(APPS.boom_math_teacher)}${ls('go_impersonating') ? '/impersonate' : ''
                                }?token=${token}`}
                              className={styles.appLink}
                            >
                              <span className={styles.appLetter}>M</span>Math
                            </a>
                          )}
                        </>
                      ) : (
                        <>
                          {source === 'READING' ? (
                            <>
                              <span className={styles.subHeading}>Reader</span>
                              <div className={styles.subSet}>
                                <Link
                                  onClick={() => setShowMenu(false)}
                                  className={styles.subLink}
                                  to="/dataDashboard"
                                >
                                  Data Dashboard
                                </Link>
                                <Link
                                  onClick={() => setShowMenu(false)}
                                  className={styles.subLink}
                                  to="/"
                                >
                                  Reading Bands
                                </Link>
                              </div>
                            </>
                          ) : user.school.canAccessGoReadApp ? (
                            <a
                              href={`${getUrl(APPS.go_read_teacher)}${ls('go_impersonating') ? '/impersonate' : ''
                                }?token=${token}`}
                              className={styles.appLink}
                            >
                              <span className={styles.appLetter}>R</span>Reader
                            </a>
                          ) : (
                            <span className={styles.appLink}>Reader Locked</span>
                          )}
                          {source === 'WRITING' ? (
                            <span className={styles.subHeading}>Write</span>
                          ) : (
                            <a
                              href={`${getUrl(APPS.go_write_teacher)}${ls('go_impersonating') ? '/impersonate' : ''
                                }?token=${token}`}
                              className={styles.appLink}
                            >
                              <span className={styles.appLetter}>W</span>Writer
                            </a>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className={styles.endSet}>
                    <a
                      onClick={() => setShowMenu(false)}
                  href="https://boomhub.freshdesk.com/support/home"
                      target="_blank"
                      className={styles.endLink}
                    >
                      <HelpCircle className={styles.endLinkIcon} size="16" />
                      Help &amp; Guides
                    </a>
                    <Link
                      onClick={() => setShowMenu(false)}
                      to="/logout"
                      className={styles.endLink}
                    >
                      <LogOut className={styles.endLinkIcon} size="16" />
                      Logout
                    </Link>
                  </div>
                </div>
              <XCircle className={styles.closeBtn} size={32} onClick={() => setShowMenu(false)} />
            </div>
          </div>
      )}
        </>
      );
}

      export default Nav;
